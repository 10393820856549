/*
 * 업무구분: 수익증권>메인
 * 화 면 명: MSPFS000M
 * 화면설명: 수익증권 메인페이지
 * 작 성 일: 2023.03.27
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="수익증권메인" :topButton="false">
    <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-main-fs"  @click="fn_GoJobTypPage" :borderless="true">홈으로</mo-icon-button>
          <mo-icon-button class="fts-gnb" @click="fn_MenuGnb" :borderless="true">메뉴 열기/닫기</mo-icon-button>
        </nav>
        <ul class="fts-main-util row">
          <li class="work-type">            
            <mo-badge :class="lv_visitType === 'vst'? 'visit':'comein'" :text="lv_visitType === 'vst'? '방문':'내방'" />
          </li>
          <li class="logout">
            <a href="javascript:void(0)" @click="fn_ExitApp">로그아웃</a>
          </li>
        </ul>
      </ur-box-container>
    </header>

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="row">
        <div class="wrap-fts-mainpage">
          <mo-carousel class="fs-carousel" number-of-page="2" :no-rotate="true">
            <template #1>

              <!-- 고객 -->
              <div class="wrap-customer wrap-fs bg-blue">
                <h1> 고객 </h1>
                <div class="wrap-block">
                  <ul class="row">
                    <li class="regist">
                      <mo-button class="fts-main-button" @click="openModal(1)"> <span>고객정보등록</span> </mo-button>
                    </li>
                    <li class="manage">
                      <mo-button class="fts-main-button" @click="fn_GoPage('plzCustMng')"> <span>플라자별 고객관리</span> </mo-button>
                    </li>
                    <li class="fund">
                      <mo-button class="fts-main-button" @click="fn_GoPage('intrnFndDl')"> <span>인터넷펀드 거래서비스 신청</span> </mo-button>
                    </li>
                    <li class="analysis">
                      <mo-button class="fts-main-button" @click="fn_GoPage('custIvtrPrpnsAnly', '1')"> <span>고객투자자 성향분석</span> </mo-button>
                    </li>
                  </ul>
                  <ul class="row">
                    <li class="info">
                      <mo-button class="fts-main-button" @click="openModal(2)"> <span>고객정보조회</span> </mo-button>
                    </li>
                    <li class="info">
                      <mo-button class="fts-main-button" @click="openModal(6)"> <span>대리인/조력자 등록</span> </mo-button>
                    </li>
                    <li class="wrap-bg"></li>
                    <li class="result">
                      <mo-button class="fts-main-button" @click="fn_GoPage('custIvtrPrpnsAnly', '2')"> <span>고객투자자 성향분석 결과 조회</span> </mo-button>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- 상품 -->
              <div class="wrap-products wrap-fs bg-purple">
                <h1> 상품 </h1>
                <div class="wrap-block">
                  <ul class="row">
                    <li class="fundsearch">
                      <mo-button class="fts-main-button" @click="fn_GoPage('fndInqr', '1')"> <span>펀드종합검색</span> </mo-button>
                    </li>
                    <li class="wrap-bg">
                    </li>
                  </ul>
                  <ul class="row">
                    <li class="fundinfo">
                      <mo-button class="fts-main-button" @click="fn_GoPage('fndInqr', '2')"> <span>펀드기본정보 조회</span> </mo-button>
                    </li>
                    <li class="salesdata">
                      <mo-button class="fts-main-button" @click="fn_GoPage('salesData')"> <span>판매자료조회</span> </mo-button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template #2>

              <!-- 펀드업무 -->
              <div class="wrap-sales wrap-fs bg-lightblue">
                <h1> 펀드업무 </h1>
                <div class="wrap-block">
                  <ul class="row">
                    <li class="sales-newgroup">
                      <mo-button class="fts-main-button" @click="fn_GoPage('new', '1')"> <span>그룹신규</span> </mo-button>
                    </li>
                    <li class="sales-buyfund">
                      <mo-button class="fts-main-button" @click="fn_GoPage('fndDl', '1')"> <span>펀드매입</span> </mo-button>
                    </li>
                    <li class="sales-apply">
                      <mo-button class="fts-main-button" @click="openModal(3)"> <span>매매신청내역 조회</span> </mo-button>
                    </li>
                    <li class="wrap-bg-01">
                    </li>
                    <li class="sales-edit">
                      <mo-button class="fts-main-button" @click="fn_GoPage('custInfoMng', '1')"> <span>고객정보정정</span> </mo-button>
                    </li>
                    <li class="sales-lockoff">
                      <mo-button class="fts-main-button" @click="fn_GoPage('custInfoMng', '2')"> <span>비밀번호제한 해제</span> </mo-button>
                    </li>
                  </ul>
                  <ul class="row">
                    <li class="sales-newaccount">
                      <mo-button class="fts-main-button" @click="fn_GoPage('new', '2')"> <span>계좌신규</span> </mo-button>
                    </li>
                    <li class="sales-redemt">
                      <mo-button class="fts-main-button" @click="fn_GoPage('fndDl', '2')"> <span>펀드환매</span> </mo-button>
                    </li>
                    <li class="sales-cancel">
                      <mo-button class="fts-main-button" @click="fn_GoPage('dlCanc')"> <span>거래취소</span> </mo-button>
                    </li>
                    <li class="sales-banking">
                      <mo-button class="fts-main-button" @click="openModal(4)"> <span>이체/대체</span> </mo-button>
                    </li>
                    <li class="wrap-bg-02">
                      <!-- <mo-button class="fts-main-button" @click="fn_GoPage('procTest')"> <span>프로세스(T)</span> </mo-button> -->
                      <!-- <mo-button class="fts-main-button" @click="fn_GoPage('fndInfo')"> <span>펀드정보</span> </mo-button> -->
                    </li>
                    <li class="sales-file">
                      <mo-button class="fts-main-button" @click="openModal(7)"> <span>파일재전송</span> </mo-button>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </mo-carousel>
        </div>
      </ur-box-container>

      <!-- Snackbar 스낵바 (테스트 버튼을 클릭하면 스낵바가 나옴. 일단 주석처리함.) -->
      <!-- <mo-button class="test-snackbar" @click="addSnackbar"> 스낵바테스트버튼 </mo-button> -->
      <mo-snackbar ref="stackableSnackbar" stackable :duration="3000"></mo-snackbar>
    </main>
    <!-- //.fts-main -->    
    <!-- popup : 고객 > 고객정보등록 -->
    <mo-modal class="fts-modal medium main-popup" ref="custInfoReg" title="고객정보등록" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'custInfoReg')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 고객정보조회 -->
    <mo-modal class="fts-modal medium main-popup" ref="custInfoInqr" title="고객정보조회" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'custInfoInqr')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 펀드업무 > 매매신청내역조회 -->
    <mo-modal class="fts-modal medium main-popup" ref="slnbyAplBrkdnInqr" title="매매신청내역조회" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'slnbyAplBrkdnInqr')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 펀드업무 > 이체/대체 -->
    <mo-modal class="fts-modal medium main-popup" ref="trsfRplc" title="이체/대체" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'trsfRplc')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- 전자서식 전송중 팝업 -->
    <mo-modal class="fts-modal small" ref="progress" no-foot>
      <template>
        <div class="column align-center">
          <div class="div-progress div-progress-circular">
            <mo-determinate-progress shape="circle" :value="valueLoading" size="large" width="20px"></mo-determinate-progress>
            <span class="text-loading">Loading</span>
          </div>
          <!-- <span class="wrap-text blue"> 전자서식 전송중 </span> -->

          <!-- 녹취파일 삭제중일때 -->
          <span class="wrap-text blue"> 녹취파일 전송 및 기기 삭제 중 </span>
        </div>
      </template>
    </mo-modal>

    <!-- popup010 -->
    <msp-fs-010p
      ref="popup010"      
      :popupObj="pPopup010Obj"
    ></msp-fs-010p>

    <!-- popup : 고객 > 대리인/조력자 등록 팝업 -->
    <mo-modal class="fts-modal medium main-popup" ref="addRepresent" title="대리인/조력자 등록" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'agntAssi')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>
    <!-- alert modal popup -->

    <!-- popup : 펀드업무 > 문서/파일 재전송 팝업 -->
    <mo-modal class="fts-modal medium main-popup" ref="reTrns" title="문서/파일 재전송" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'reTrns')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>
    <!-- alert modal popup -->

    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
    
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPFS010P from '~/src/ui/fs/MSPFS010P'  // 전체메뉴
import FSMenuUtil from '~/src/ui/fs/comm/FSMenuUtil'
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)  
import FSInfoUtil from '@/ui/fs/comm/FSInfoUtil'
import FSCommUtil from '@/ui/fs/comm/FSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS000M",
  screenId: "MSPFS000M",
  components: {
    'msp-fs-010p': MSPFS010P,
    'fs-alert-popup': FSAlertPopup,
  },  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ExitApp)

    this.fn_InitMenu()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS000M");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {      
      pPopup010Obj: {},
      pPopup500Obj: {},
      pAlertPopupObj: {},
      lv_pageObj: [],
      lv_MsgLogoutConfirm: '로그아웃 하시겠습니까?',
      valueLoading: 0
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.custInfoReg}, // 고객정보등록
    modal2() {return this.$refs.custInfoInqr}, // 고객정보조회
    modal3() {return this.$refs.slnbyAplBrkdnInqr}, // 매매신청내역조회
    modal4() {return this.$refs.trsfRplc}, // 이체/대체    
    modal9() {return this.$refs.progress}, // 전자서식 진행바    
    modal6() {return this.$refs.addRepresent}, // 대리인/조력자등록 팝업
    modal7() {return this.$refs.reTrns}, // 문서/파일재전송 팝업
    lv_visitType() {
      let rtn = this.getStore('fsStore').getters.getBasInfo.data.vstTyp
      return (rtn)? rtn : ''
    },
    lv_basInfo() {
      return this.getStore('fsStore').getters.getBasInfo.data
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {    
     /******************************************************************************
      * Function명 : openModal
      * 설명       : 팝업 열기
      ******************************************************************************/
    openModal(type) {      
      for(let i=1; i<101; i++) {
        this["modal"+type].open(); break;
      }
    },
    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
      * Function명 : fn_GoJobTypPage
      * 설명       : 업무구분 페이지 이동
      ******************************************************************************/
    fn_GoJobTypPage(){
      let t_popupObj = {        
        cancel: true,
        confirm: true,
        confirmFunc: async function() {
          this.$bizUtil.fsUtils.saveProcTyp(this) // 초기화
          this.$bizUtil.fsUtils.saveDocData(this) // 고객 서류 초기화
          this.$bizUtil.fsUtils.saveJobTyp(this) // 업무구분 초기화 (fs / ts)
          this.$bizUtil.fsUtils.saveBasInfo(this, { vstTyp: '' }) // 내방/방문 초기화
          await this.$router.push({
            name: 'MSPFS002M'
          })
        },
        content: "수익증권/신탁 선택화면으로 이동하시겠습니까?"
      }
      this.fn_AlertPopup(1, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_InitMenu
     * 설명       : 메뉴 초기화
     ******************************************************************************/
    fn_InitMenu() {      
      this.lv_pageObj = FSMenuUtil.gfn_InitMenu()
    },

    // 메뉴 열기
    fn_MenuGnb() {
      this.$refs.popup010.fn_Open()
    },
    
    /******************************************************************************
    * Function명 : fn_GoPage
    * 설명       : 페이지 이동
    * 파라미터(type, idx) : 중분류, 인덱스 번호
    ******************************************************************************/    
    fn_GoPage(type, idx = '1') {      
      let pageObj = this.lv_pageObj.filter(p=> p.level === 'S' && p.value === type && p.idx === idx)[0]
      console.log('fn_GoPage >', pageObj, type, idx)

      if(!pageObj.params.isRecord) {
        this.getStore('fsStore').dispatch('IS_RECORDING_STOP')
      }

      if(pageObj.params.isStep) {         
        let t_IsProcess = this.getStore('fsStore').getters.getState.isProcess
        let procTyp =  this.getStore('fsStore').getters.getBasInfo.data.procTyp
        if ( !t_IsProcess ) {
          this.getStore('fsStore').dispatch('IS_PROCESS_START')
          this.$bizUtil.fsUtils.saveProcTyp(this, pageObj.params.procTyp)
        } else {
          if (pageObj.params.procTyp !== procTyp) {
            this.$bizUtil.fsUtils.saveProcTyp(this, pageObj.params.procTyp)
          }
        } 
      } else {
        let t_IsProcess = this.getStore('fsStore').getters.getState.isProcess
        console.log('fn_GoPage > ', t_IsProcess)
        this.$bizUtil.fsUtils.saveProcTyp(this)
        this.getStore('fsStore').dispatch('IS_PROCESS_STOP')
      }
            
      this.$router.push({
        name: pageObj.page,
        params: pageObj.params
      })
    },
    /******************************************************************************
    * Function명 : fn_GoSubPage
    * 설명       : 페이지 이동
    * 파라미터(obj) : 페이지 객체
    ******************************************************************************/    
    fn_GoSubPage(obj) {
      let pageObj = obj

      if(!pageObj.params.isRecord) {
        this.getStore('fsStore').dispatch('IS_RECORDING_STOP')
      }

      if(pageObj.params.isStep) {
        let t_IsProcess = this.getStore('fsStore').getters.getState.isProcess
        let procTyp =  this.getStore('fsStore').getters.getBasInfo.data.procTyp
        if ( !t_IsProcess ) {
          this.getStore('fsStore').dispatch('IS_PROCESS_START')
          this.$bizUtil.fsUtils.saveProcTyp(this, pageObj.params.procTyp)
        } else {
          if (pageObj.params.procTyp !== procTyp) {
            this.$bizUtil.fsUtils.saveProcTyp(this, pageObj.params.procTyp)
          }
        } 
      } else {
        let t_IsProcess = this.getStore('fsStore').getters.getState.isProcess
        console.log('fn_GoPage > ', t_IsProcess)
        this.$bizUtil.fsUtils.saveProcTyp(this, '')
        this.getStore('fsStore').dispatch('IS_PROCESS_STOP')
      }
            
      this.$router.push({
        name: pageObj.page,
        params: pageObj.params
      })
    },

    /******************************************************************************
    * Function명 : fn_ExitApp
    * 설명       : App 종료 액션
    ******************************************************************************/    
    fn_ExitApp () {
    // console.log('##### fn_ExitApp #####')
    let lv_Vm = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            window.fdpbridge.exec('logoutPlugin', {}, () => {}, () => {})
            console.log('fn_ExitApp - logout !')
            lv_Vm.$router.push({
              name: 'MSPBC600M'
            })
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    
  }
}
</script>
<style scoped>
</style>